'use client';

import { useEffect, useState } from "react";

export interface SDK {
  loading: boolean;
  initialized: boolean;
  scanUpc: (code: string) => Promise<Wine>;
  listWines: () => Promise<Wine[]>;
  toBuyWine: (code: string) => Promise<void>;
  addWine: (code: string) => Promise<void>;
  openWine: (code: string) => Promise<void>;
  finishWine: (code: string) => Promise<void>;
  returnWine: (code: string) => Promise<void>;  
}

export interface WineAttribute {
  name: string;
  value: string;
}

export interface WineInventory {
  id: string;
  quantity: number;
}

export interface Wine {
  name: string;
  image: string;
  price: number;
  rating: number;
  upc: string;  
  attributes: WineAttribute[];
  inventory: WineInventory[];
}

export const useSDK = (): SDK => {
    const apiUrl = process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:5124/api/';
    const [loading, setLoading] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [lastError, setLastError] = useState<Error | null>(null);
    const [settings, setSettings] = useState<any | null>(null);

    useEffect(() => {
      const initialize = async () => {
        const settings = { url_api: apiUrl };
        if(settings){
          setSettings(settings);
          setInitialized(true);
        }
      };
      initialize();
    }, []);
    
    const wrappedDownload = async (path:string, formData:any = null, authenticatedRequest: boolean = true, method: string = "GET", spinner: boolean = true): Promise<Blob> => {
      spinner && setLoading(true);
      let result:any;
      try{
        const options = authenticatedRequest?
          {
              method: method,
              headers: {
                'Content-Type': 'application/json'
              }
            } as RequestInit
          :
          {
            method: method,
            headers: {
              'Content-Type': 'application/json'
            }
          } as RequestInit;

        if(formData){
          options.method = 'POST';
          options.body = JSON.stringify(formData);
        }

        if(method !== 'GET') options.method = method;

        const response = await fetch(settings?.url_api + path, options);  
        if (!response.ok) {
          console.error('Failed to submit request');
          throw new Error('Failed to submit request');          
        }
        if(response.status === 204){
          throw new Error('No Content (204)');          
        }
        result = await response.blob();        
      } catch (error) {
        console.error('Error submitting form:', error);
        throw error;
      } finally {
        setLoading(false);
      }
      return result;
    };

    const wrappedFetch = async <T extends {} | null | void> (path:string, formData:any = null, authenticatedRequest: boolean = true, method: string = "GET", spinner: boolean = true) : Promise<T> => {      
      setLoading(spinner);
      let result:T;
      try{
        const options = authenticatedRequest?
          {
              method: method,
              headers: {
                'Content-Type': 'application/json'
              }
            } as RequestInit
          :
          {
            method: method,
            headers: {
              'Content-Type': 'application/json'
            }
          } as RequestInit;

        if(formData){
          options.method = 'POST';
          options.body = JSON.stringify(formData);
        }

        if(method !== 'GET') options.method = method;

        const response = await fetch(settings?.url_api + path, options);  
        if (!response.ok) {
          console.error('Failed to submit request');
          throw new Error('Failed to submit request');          
        }
        if(response.status === 204){
          return null as T;
        }
        // json() only if it json
        if(response.headers.get('content-type')?.includes('application/json')){
          result = await response.json() as T;
        }
        else{
          result = null as T;
        }
      } catch (error) {
        setLastError(new Error(error?.toString()));
        console.error('Error submitting form:', error);
        result = null as T;
      } finally {        
        setLoading(false);
      }
      return result;
    };

    return {
      loading,
      initialized,
      toBuyWine: async (upc: string) => wrappedFetch<void>('inventory/tobuy?upc='+upc),
      scanUpc: async (upc: string) => wrappedFetch<Wine>('inventory/scan?upc='+upc),
      listWines: async () => wrappedFetch<Wine[]>('inventory/'),
      addWine: async (upc: string) => wrappedFetch<void>('inventory/add?upc='+upc+'&quantity=1'),
      openWine: async (upc: string) => wrappedFetch<void>('inventory/open?upc='+upc),
      finishWine: async (upc: string) => wrappedFetch<void>('inventory/finish?upc='+upc),
      returnWine: async (upc: string) => wrappedFetch<void>('inventory/return?upc='+upc),
    };
  };
