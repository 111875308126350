import React, { useEffect, useState } from 'react';
import './App.css';
import theme from './theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import UpcInput from './UpcInput';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider } from '@mui/material';
import { useSDK, Wine } from './sdk';
import Logo from './logo-inverted.svg';
import BarCodes from './BarCodes';
import WineProfile from './WineProfile';
import CustomButton from './CustomButton';

function App() {
  const [openBarcode, setOpenBarcode] = useState(false);
  const [openWine, setOpenWine] = useState(false);
  const [wine, setWine] = useState<Wine|null>();
  const [wines, setWines] = useState<Wine[]>([]);

  var sdk = useSDK();
  
  const handleOpenBarCode = () => setOpenBarcode(true);

  useEffect(() => {
    const initialize = async () => {
      if(sdk.initialized){
        const localWines = await sdk.listWines();
        localWines.sort((a, b) => a.price - b.price);
        setWines(localWines);
      }
    };
    initialize();
  }, [sdk.initialized]);

  const inventoryCost = wines.reduce((acc, wine) => acc + (wine.price * wine.inventory.reduce((acc, inventory) => acc + inventory.quantity, 0)), 0);

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const handleSortName = () => { 
    const sortedWines = wines.sort((a, b) => sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
    setWines([...sortedWines]);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  const handleSortPrice = () => {
    const sortedWines = wines.sort((a, b) => sortOrder === 'asc' ? a.price - b.price : b.price - a.price);
    setWines([...sortedWines]);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  const handleSortRating = () => {
    const sortedWines = wines.sort((a, b) => sortOrder === 'asc' ? a.rating - b.rating : b.rating - a.rating);
    setWines([...sortedWines]);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className='Leftmenu'>
          <div className='LeftLogo'>
            <img src={Logo} alt="logo" style={{ width: '80%'  }} />
          </div>
          <CustomButton size='large' onClick={() => setWine(null)}>Inventaire</CustomButton>
          <UpcInput/>
          <CustomButton size='large' onClick={handleOpenBarCode}>Barcodes</CustomButton>
          <div className='InvertedText'>{inventoryCost.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })}</div>
        </div>
        <div className='Content'>
          <div className='ScrollableContent'>
            <Table stickyHeader size="small" >
              <TableHead>
                  <TableRow>
                  <TableCell>Color</TableCell>
                    <TableCell><Link onClick={handleSortName}>Nom</Link></TableCell>
                    <TableCell><Link onClick={handleSortPrice}>Prix</Link></TableCell>
                    <TableCell><Link onClick={handleSortRating}>Cote</Link></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {wines.map((wine, index) => {
                  const getAttribute = (name:string) => wine.attributes.find((attribute) => attribute.name === name)?.value;
                  const totalQuantity = wine.inventory.reduce((acc, inventory) => acc + inventory.quantity, 0);
                  const totalQuantityText = totalQuantity > 1 ? `(x${totalQuantity})` : '';

                  return <TableRow key={index}>
                    <TableCell>{getAttribute("Color")}</TableCell>
                    <TableCell><Link onClick={() => {setWine(wine); setOpenWine(true);}}> {wine.name} {totalQuantityText}</Link></TableCell>
                    <TableCell>{wine.price.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })}</TableCell>
                    <TableCell>{wine.rating}</TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
          <div className="ModalContent">
            <WineProfile wine={wine} open={openWine} onClose={()=>setOpenWine(false)} />
          </div>
        </div>
        
        <BarCodes open={openBarcode} onClose={() => setOpenBarcode(false)} />
      </div>
    </ThemeProvider>
  );
}

export default App;
