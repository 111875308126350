import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#572650', // Wine/plum as the primary color
      light: '#875378', // A lighter variation for hover effects or highlights
      dark: '#3b1836', // A deeper shade for contrast
      contrastText: '#ffffff', // White text for legibility
    },
    secondary: {
      main: '#D4AF37', // Muted gold as the secondary color for accents
      light: '#E0C468', // A lighter gold for highlights
      dark: '#A48428', // A darker gold for contrast
      contrastText: '#ffffff', // White text for consistency
    },
    background: {
      default: '#F5F0E8', // Soft creamy beige for a classy background
      paper: '#FAF8F6', // Slightly brighter off-white for cards or surfaces
    },
    text: {
      primary: '#2E2E2E', // Rich charcoal gray for main text
      secondary: '#875378', // Subtle lighter plum for secondary text
      disabled: '#B3B3B3', // Muted gray for disabled elements
    },
    error: {
      main: '#D32F2F', // Red for errors
    },
    warning: {
      main: '#ED6C02', // Orange for warnings
    },
    info: {
      main: '#0288D1', // Blue for informational messages
    },
    success: {
      main: '#2E7D32', // Green for success
    },
  },
  typography: {
    fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`, // Classy and modern typography
    h1: { fontWeight: 700, fontSize: '3rem', lineHeight: 1.2 },
    h2: { fontWeight: 600, fontSize: '2.5rem', lineHeight: 1.3 },
    body1: { fontSize: '1rem', lineHeight: 1.5, color: '#2E2E2E' },
    body2: { fontSize: '0.875rem', lineHeight: 1.43, color: '#875378' },
  },
  shape: {
    borderRadius: 8, // Smooth corners for a modern, elegant look
  },
});

export default theme;
