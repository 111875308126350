import { Box, Modal } from '@mui/material';
import React from 'react';

interface ModalDialogProps  extends Omit<React.ComponentProps<typeof Modal>, 'children'> {    
    open: boolean;
    onClose?: () => void;
    width?: number;
    children?: React.ReactNode;
}

export const modalStyles = { 
    position: 'absolute', top: '50%', left: '50%', 
    transform: 'translate(-50%, -50%)', 
    bgcolor: 'background.paper', 
    border: '1px solid #000', 
    borderRadius: 2,
    boxShadow: 24, p: 1,  
    padding: 5,
  }

const ModalDialog: React.FC<ModalDialogProps> = ({open, onClose, width, children, ...props}) => {
    const localWidth = width || '600px';
    const localStyles = { ...modalStyles, width: localWidth };

    return (
        <Modal open={open} onClose={onClose} {...props}>
            <Box sx={localStyles}>
                {children}
            </Box>
        </Modal>
    );
};

export default ModalDialog;