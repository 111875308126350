import React from 'react';
import Barcode from 'react-barcode';
import ModalDialog from './ModalDialog';
import { Box } from '@mui/material';

interface BarCodesProps {
    open: boolean;
    onClose: () => void;
}

const BarCodes: React.FC<BarCodesProps> = ({open, onClose}) => {
    return (
        <ModalDialog open={open} onClose={onClose} width={300}>
            <Box  alignContent={'center'} textAlign={'center'}>
                <Barcode value="%%ALL-CH" format="CODE128" displayValue={false} background='#00000000' />
                <h3>Pair Bluetooth</h3>
                <Barcode value="%#IFSNO$4" format="CODE128" displayValue={false} background='#00000000' />
                <h3>Unpair Bluetooth</h3>
                <Barcode value="641734000036" format="CODE128" displayValue={false} background='#00000000' />
                <h3>Test</h3>
            </Box>
        </ModalDialog>
    );
};

export default BarCodes;