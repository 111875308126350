import React, { ChangeEventHandler, useState } from 'react';
import { Box, TextField, Stack, Button, Checkbox, FormControlLabel, Rating } from '@mui/material';
import { useEffect, useRef } from 'react';
import ModalDialog from './ModalDialog';
import CustomButton from './CustomButton';
import { useSDK, Wine } from './sdk';
import { isValidBarcode } from './utils';

export const modalStyles = { 
  position: 'absolute', 
  top: '50%', 
  left: '50%', 
  transform: 'translate(-50%, -50%)', 
  width: 600, 
  bgcolor: 'background.paper', 
  border: '1px solid #000', 
  borderRadius: 2,
  boxShadow: 24, 
  p: 1,  
}

interface CodeInputProps {
    onClose?: () => void;
}

const UpcInput: React.FC<CodeInputProps> = ({ onClose }) => {
  const audioBeep = new Audio('/sounds/beep.mp3');
  const audioCork = new Audio('/sounds/cork.mp3');
  const [code, setCode] = useState<string>('');
  const [openScan, setOpenScan] = useState(false);  
  const [inventoryMode, setInventoryMode] = useState<boolean>(false);
  const [wine, setWine] = useState<Wine|null>();
  const sdk = useSDK();

  const hasValidCode = code.length>0;  
  const allButtonsDisabled = !(code.length > 0 && !inventoryMode);
  const wineOpacity = hasValidCode ? { opacity: 1 } : { opacity:0.4 };

  const localStyles = { ...modalStyles, width: '700px' };
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpenScan = () =>  setOpenScan(true);
  const handleClose = () => {
    if(onClose) onClose();
    setCode('');
    setWine(null);
    setOpenScan(false);
  };

  const handleInventoryMode = (e: React.ChangeEvent<HTMLInputElement>) => setInventoryMode(e.target.checked);

  const loadWine = async () => {
    const wine = await sdk.scanUpc(code);
    setWine(wine);
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.toUpperCase();    
    setCode(value);
  }

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {    
    if (e.key === 'Enter') {      
      loadWine();
      if(inventoryMode){
        await sdk.addWine(code);
        setCode('');
        inputRef.current?.focus();        
        audioBeep.play();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const pastedData = e.clipboardData.getData('Text').toUpperCase();    
    setCode(pastedData);
    loadWine();
    e.preventDefault();
  };

  const handleToBuy = async () => {
    await sdk.toBuyWine(code);
    setCode('');
    onClose?.();
    inputRef.current?.focus();
  }

  const handleAdd = async () => {
    await sdk.addWine(code);
    setCode('');
    onClose?.();
    inputRef.current?.focus();
  }

  const handleOpen = async () => {
    await sdk.openWine(code);
    setCode('');
    onClose?.();
    inputRef.current?.focus();
    audioCork.play();
  }

  const handleFinish = async () => {
    await sdk.finishWine(code);
    setCode('');
    onClose?.();
    inputRef.current?.focus();
  }

  const handleReturn = async () => {
    await sdk.returnWine(code);
    setCode('');
    onClose?.();
    inputRef.current?.focus();
  }

  const handleDetail = async () => {
    setCode('');
    onClose?.();
    inputRef.current?.focus();
  }

  useEffect(() => {
    if (openScan) {
      const timeout = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [openScan]);

  const getAttribute = (name:string) => wine?.attributes.find((attribute) => attribute.name === name)?.value;

  return <>
    <CustomButton size='large' onClick={handleOpenScan}>Scan</CustomButton>
    <ModalDialog open={openScan} onClose={handleClose} width={700} disableAutoFocus>
      <Stack direction="column" gap={2} justifyContent="center" alignItems="center">
        <Stack direction="column" gap={2}>
          {wine && <Stack direction="row" gap={2} justifyContent="center" alignItems="center" sx={wineOpacity}>
            <img src={wine.image} alt={wine.name} style={{ height: '30vh' }} />
            <Box>
              <span>{getAttribute("Country")}, {getAttribute("Region")} | {getAttribute("Grape")}</span>
              <h1>{wine?.name}</h1>
              <h2>{wine?.price}$</h2>
              <Rating name="simple-controlled" value={(wine?.rating??0)/10} max={10} size={"small"} />
            </Box>
          </Stack>}
        </Stack>
        <TextField
            inputRef={inputRef}
            slotProps={{ 
              htmlInput: { 
                maxLength: 15, 
                style: { 
                  letterSpacing: '10px', 
                  fontFamily: 'monospace', 
                  fontSize: '30pt', 
                  textTransform: 'uppercase', 
                  textAlign: 'center' 
                }}
              }}
            value={code}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            variant="outlined"
            size="small"
            sx={{ width: '600px' }}
            inputMode='numeric'
            type="number"
          />
        <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
          <Button variant="contained" onClick={handleToBuy} disabled={allButtonsDisabled} size='large'>A acheter</Button>
          <Button variant="contained" onClick={handleAdd} disabled={code.length === 0} size='large'>Ajouter</Button>
          <Button variant="contained" onClick={handleOpen} disabled={allButtonsDisabled} size='large'>Ouvrir</Button>
          <Button variant="contained" onClick={handleFinish} disabled={allButtonsDisabled} size='large'>Finir</Button>
        </Stack>
        <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
          <FormControlLabel control={<Checkbox value={inventoryMode} onChange={handleInventoryMode} />} label="Mode inventaire" />
          <Button variant="contained" onClick={handleDetail} disabled={allButtonsDisabled}>Voir</Button>
          <Button variant="contained" onClick={handleReturn} disabled={allButtonsDisabled}>Retourner</Button>
          <Button variant="outlined" onClick={()=>setCode('')}>Effacer</Button>
          <Button variant="outlined" onClick={handleClose}>Fermer</Button>
        </Stack>
      </Stack>
    </ModalDialog>
  </>;
};

export default UpcInput;
