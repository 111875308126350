import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButtonStyled = styled(Button)({
    width: '75%',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    color: '#fff',
    borderColor: '#572650',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    '&:hover': {
        backgroundColor: '#572650',
    },
});

const CustomButton: React.FC<ButtonProps> = (props) => {
    return <CustomButtonStyled {...props} />;
};

export default CustomButton;